<template>
  <div class="readme-article">
    <h2 id="素材库">5.素材库</h2>
    <h3 id="素材分组">5.1素材分组</h3>
    <p>素材分组设置，方便用户查找筛选素材，管理员可新增、编辑、删除素材分组。</p>
    <p>
      <img src="@/assets/img/scrm/3-12.png" />
    </p>
    <h3 id="素材列表">5.2素材列表</h3>
    <p>查看已添加素材列表，默认查看所有，可切换素材分类，新增、编辑、删除素材。</p>
    <p>
      <img src="@/assets/img/scrm/3-13.png" />
    </p>
    <h3 id="素材编辑">5.3素材编辑</h3>
    <p>素材列表页切换类型可添加该类型的素材，不同类型的素材添加填写的内容不同，填写内容如下图展示。</p>
    <p>
      <img src="@/assets/img/scrm/3-14.png" />
    </p>
    <p>
      <img src="@/assets/img/scrm/3-15.png" />
    </p>
    <p>
      <img src="@/assets/img/scrm/3-16.png" />
    </p>
    <p>
      <img src="@/assets/img/scrm/3-17.png" />
    </p>
    <p>
      <img src="@/assets/img/scrm/3-18.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm3-5",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>